.App {
  text-align: center;
  color: #fff;
  /* font-family:; */

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-page{
  margin: 0 auto;
  height: 100vh;

  /* max-width: 1280px; */
}

.main-top{
  width: 100vw;
  background-color: #000;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  align-items: center;
  position: fixed;
}

.main-top-logo{
  padding: 20px 80px;
}
/* .main-top-logo img{
  width: 60px;
} */

.main-top-logo h1{
  margin: 0;
  
}

.main-info{
  background: rgb(53,182,235);
  background: linear-gradient(90deg, rgba(53,182,235,1) 25%, rgba(255,156,213,1) 50%, rgba(236,64,32,1) 75%);
    width: 100vw;
  height: auto;
  display: flex;
  padding-top: 140px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
}

.main-info-title{
  margin: 0;
  width: 40%;
  /* text-shadow: 0 4px 4px rgb(61, 56, 56); */
  font-size: 32px;
}

.main-info h3{
  font-size: 18px;
  font-weight: 300;
  line-height: 14px;
  /* text-shadow: 0 4px 4px rgb(61, 56, 56); */

}
.main-info h4{
  font-size: 14px;
  font-weight: 300;
}
.main-card{
  background: #EAEAEA;
  width: 100vw;
  height: auto;
  padding: 50px 0;
}

.main-card h1{
  margin: 0;
  background: linear-gradient(90deg, rgba(53,182,235,1) 25%, rgba(236,64,32,1) 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: bold;
}

.main-card-container{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 0 80px;

}

.main-card-container img{
  width:30%;
  object-fit: cover;
}
.main-tester{
  background: #EAEAEA;
  width: 100vw;
  height: auto;
  /* padding: 80px 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}
.main-tester h2{
  color: #000;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 25px;
}

.mail-button-beta{
  margin-top: 40px;
}

.main-tester h5{
  color: #000;
  margin: 0;
  padding-top: 30px;
  color: #333333;
  font-weight: 14px;
}


.mail-button-container form {
  height: 42px;
  display: flex;
  justify-content: space-between;
  width: 380px;
  margin-top: 20px;
}

.mail-button-input{
  border-radius: 10px;
  padding: 10px;
  border: 1px solid white;
  width: 60%;
  color: #fff !important;
  background-color: rgba(000, 0, 0, 0.2)  !important;
  font-weight: bold;
}

.mail-button-input::placeholder{
  color: white;
}

.mail-button-submit{
  color: white;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #08A3E3;
  width: 30%;
  cursor: pointer;
}

.mail-button-submit:hover{
  background-color: #1988b8;
  transition: 0.3s ease;
}

.mail-button-beta-input{
  width: 60%;
  border: 1px solid black;
  /* border-radius: 10px; */
  padding: 10px;
  font-weight: bold;

}

.mail-button-beta-submit{
  font-weight: bold;
  cursor: pointer;
  width: 30%;
  border: none;
  background-color: #000;
  color: white;
}

.mail-button-beta-input::placeholder{
  color: #979797;
}

.mail-button-beta-submit:hover{
  transition: 0.3s ease;
  background-color: #595555;
}

@media (max-width: 650px){
  .main-info-title{
    width: 90%;
  }
  .main-top{
    justify-content: center;
  }
}

@media (max-width: 450px){
  .main-info-title{
    font-size: 20px;
  }
  .mail-button-container form{
    width: 330px;
  }
  .main-tester h2{
    font-size: 20px;
  }
  .main-card-container{
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    margin-top: 0;
  }
  .main-card-container img{
    width: 80%;
    margin-top: 20px;
  }
  .main-info h3{
    font-size: 12px;
  }
}